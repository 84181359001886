<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="8" class="py-0">
          <span class="primary--text">Búsqueda de comprobantes a cancelar</span>
        </v-col>
        <v-col cols="4" class="py-0 pr-2">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="to-right" icon @click="closeModal">
                <v-icon v-bind="attrs" v-on="on">{{ closeIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text class="">
      <v-row class="px-2">
        <v-col
          cols="12"
          md="10"
          class="py-0"
          align="left"
          @click="showFilters = true"
        >
          <FiltersSelected :filters="filtersApplied" v-if="!showFilters" />
        </v-col>
        <v-col cols="12" md="2" align="right" align-self="center" class="pt-0">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="primary--text"
                @click.stop="showFilters = !showFilters"
              >
                {{ closeFiltersIcon }}
              </v-icon>
            </template>
            <span>{{
              !showFilters ? "Mostrar filtros" : "Ocultar filtros"
            }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-form
          v-show="showFilters"
          v-model="isFormValid"
          ref="form-busqueda-comprobantes"
          form="form-busqueda-comprobantes"
          id="form-busqueda-comprobantes"
          @submit.prevent="applyFilters()"
        >
          <v-row>
            <v-col cols="5" class="py-0 pr-1">
              <v-text-field
                label="Empresa"
                disabled
                v-model="empresa.value"
                outlined
                dense
                filled
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="py-0 pl-1 pr-1">
              <v-text-field
                label="Moneda proveedor"
                v-model="monedaProv.value"
                disabled
                filled
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="5" class="py-0 pl-1" v-if="!pagosMasivosFilter">
              <v-text-field
                label="Proveedor"
                v-model="proveedor.value1"
                outlined
                dense
                disabled
                filled
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5" class="py-0 pl-1" v-else>
              <v-row>
                <!-- DATOS PROVEEDOR -->
                <v-col cols="12" md="11" class="py-0">
                  <v-text-field
                    type="text"
                    outlined
                    dense
                    filled
                    readonly
                    clearable
                    @change="resetProvFields()"
                    @click:clear="onClear"
                    ref="proveedorTextField"
                    label="Proveedor"
                    hide-details="auto"
                    :loading="proveedoresLoading"
                    v-model.trim="provNom"
                    autocomplete="off"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="1" class="pt-2 pl-1">
                  <v-tooltip top max-width="50%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        class="px-0"
                        v-bind="attrs"
                        v-on="on"
                        tabindex="-1"
                        @click="toggleModalBusquedaProv"
                        v-if="true"
                      >
                        {{ searchIcon }}
                      </v-icon>
                    </template>
                    <span>Buscar proveedor por nombre, CUIT o código</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="pb-0 pt-2 pr-1">
              <v-select
                label="Tipo de fecha"
                :items="tiposFecha"
                v-model="tipoFecha"
                return-object
                :rules="rules.required"
                item-text="value"
                item-value="id"
                outlined
                dense
                hide-details="auto"
              ></v-select>
            </v-col>
            <!-- fecha desde -->
            <v-col cols="3" class="pb-0 pt-2 pr-1 pl-1">
              <v-menu
                ref="fecha-desde"
                v-model="menuFechaDesde"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaDesdeSelected"
                    label="Fecha desde"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaDesde = parseDateToIso(fechaDesdeSelected)"
                    outlined
                    dense
                    clearable
                    v-mask="'##/##/####'"
                    v-on="on"
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(fechaDesdeSelected)) <=
                          new Date(parseDateToIso(fechaHastaSelected)) ||
                          'Formato incorrecto'
                      )
                    "
                    hide-details="auto"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaDesde"
                  no-title
                  @change="fechaDesdeSelected = formatDate(fechaDesde)"
                  @input="menuFechaDesde = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- fecha hasta -->
            <v-col cols="3" class="pb-0 pt-2 pr-1 pl-1">
              <v-menu
                ref="fecha-hasta"
                v-model="menuFechaHasta"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaHastaSelected"
                    label="Fecha hasta"
                    :append-icon="calendarIcon"
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaHasta = parseDateToIso(fechaHastaSelected)"
                    outlined
                    autocomplete="not"
                    dense
                    clearable
                    v-mask="'##/##/####'"
                    v-bind="attrs"
                    v-on="on"
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(fechaDesdeSelected)) <=
                          new Date(parseDateToIso(fechaHastaSelected)) ||
                          'Formato incorrecto'
                      )
                    "
                    hide-details="auto"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaHasta"
                  no-title
                  @change="fechaHastaSelected = formatDate(fechaHasta)"
                  @input="menuFechaHasta = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <!-- Filtros pagos masivos -->
            <v-col cols="3" class="pb-0 pt-2 pl-1" v-if="pagosMasivosFilter">
              <v-select
                label="Tipo de valor del prov."
                :items="tiposValoresProv"
                v-model="tipoValorProvSelected"
                return-object
                item-text="value"
                item-value="id"
                outlined
                :disabled="proveedorSelected != null"
                dense
                clearable
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col cols="4" class="pb-0 pt-2 pr-1" v-if="pagosMasivosFilter">
              <v-text-field
                label="Modalidad de pago"
                v-model="modalidadPago"
                outlined
                dense
                :disabled="proveedorSelected != null"
                clearable
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="3"
              class="pb-0 pt-2 pl-1 pr-1"
              v-if="pagosMasivosFilter"
            >
              <v-select
                label="Tipo de proveedor"
                :items="tiposProveedor"
                v-model="tipoProveedorSelected"
                return-object
                item-text="value"
                item-value="id"
                outlined
                dense
                :disabled="proveedorSelected != null"
                clearable
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col
              cols="2"
              class="pb-0 pt-2 pl-1 pr-1"
              v-if="pagosMasivosFilter"
            >
              <v-text-field
                label="Período"
                v-model="periodo"
                outlined
                dense
                clearable
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="pb-0 pt-2 pl-1" v-if="pagosMasivosFilter">
              <v-select
                label="Agrup. geográfica"
                :items="agrupacionesGeografica"
                v-model="agrupGeograficaSelected"
                return-object
                @change="setEstructuras()"
                item-text="value"
                item-value="id"
                outlined
                dense
                clearable
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col cols="3" class="pb-0 pt-2 pr-1" v-if="pagosMasivosFilter">
              <v-autocomplete
                class="p-0"
                v-model="grupoLocalidadesSelected"
                :items="gruposLocalidades"
                label="Grupo de localidades"
                item-text="value"
                multiple
                item-value="id"
                outlined
                clearable
                hide-details="auto"
                dense
                return-object
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="grupoLocalidadesToggle()">
                    <v-list-item-action>
                      <v-icon>
                        {{ multiselectIconGrupoLocalidades }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ grupoLocalidadesSelected.length - 1 }} otros)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="3" class="pb-0">
              <v-btn
                :class="pagosMasivosFilter ? '' : 'to-right'"
                type="submit"
                form="form-busqueda-comprobantes"
                :loading="isApplyLoading"
                :disabled="!isFormValid"
                small
                color="primary"
                >Aplicar</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-expand-transition>
      <v-data-table
        :headers="computedHeaders"
        :items="comprobantes"
        item-value="comprobante"
        item-text="comprobante"
        item-key="mProvId"
        class="elevation-1 custom-data-table"
        :items-per-page="5"
        show-select
        v-model="selected"
        :loading="isLoadingTable"
        dense
        :search="search"
      >
        <template v-slot:top>
          <v-row>
            <v-col cols="12" md="6" class="pb-0 pl-6">
              <v-text-field
                v-model="search"
                :append-icon="searchIcon"
                label="Buscar"
                dense
                outlied
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" class="pb-0 text-right">
              <v-text-field
                label="Total"
                v-model="total"
                class="right-input"
                disabled
                outlined
                dense
                filled
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="pb-0 pt-4 pr-5">
              <v-btn
                @click="agregarSeleccionados"
                class="to-right"
                :disabled="selected.length == 0"
                color="primary"
                small
                >Agregar seleccionados</v-btn
              >
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`header.provNom`]>
          <strong style="font-size: 11px">Prov.</strong>
        </template>
        <template v-slot:[`header.nroComp`]>
          <strong style="font-size: 11px">N° comp.</strong>
        </template>
        <template v-slot:[`header.fechaComprobanteFormatted`]>
          <strong style="font-size: 11px">Fecha comp.</strong>
        </template>
        <template v-slot:[`header.fechaVencimientoFormatted`]>
          <strong style="font-size: 11px">Vencimiento</strong>
        </template>
        <template v-slot:[`header.total`]>
          <strong style="font-size: 11px" class="text-right">Total</strong>
        </template>
        <template v-slot:[`header.cancelado`]>
          <strong style="font-size: 11px">Cancelado</strong>
        </template>
        <template v-slot:[`header.saldo`]>
          <strong style="font-size: 11px">Saldo</strong>
        </template>
        <template v-slot:[`header.saldoComp`]>
          <strong style="font-size: 11px">Saldo</strong>
        </template>
        <template v-slot:[`header.cancela`]>
          <strong style="font-size: 11px; width: 100px; display: inline-block"
            >Cancela</strong
          >
        </template>
        <template v-slot:[`header.cancelaComp`]>
          <strong style="font-size: 11px; width: 100px; display: inline-block"
            >Cancela</strong
          >
        </template>
        <template v-slot:[`header.cotizacionComprobante`]>
          <strong style="font-size: 11px">Cotiz. comp.</strong>
        </template>
        <template v-slot:[`header.diferenciaCambio`]>
          <strong style="font-size: 11px">Dif. de cambio</strong>
        </template>
        <template v-slot:[`header.estadoComprobante.estado`]>
          <strong style="font-size: 11px">Estado</strong>
        </template>
        <template v-slot:[`header.actions`]>
          <strong style="font-size: 11px">Acc.</strong>
        </template>
        <template v-slot:[`item.provNom`]="{ item }">
          <span style="font-size: 12px" class="px-0 mx-0"
            >{{ item.provNom }}
          </span>
        </template>
        <template v-slot:[`item.nroComp`]="{ item }">
          <span style="font-size: 12px">{{ item.nroComp }} </span>
        </template>
        <template v-slot:[`item.fechaComprobanteFormatted`]="{ item }">
          <span style="font-size: 12px"
            >{{ item.fechaComprobanteFormatted }}
          </span>
        </template>
        <template v-slot:[`item.fechaVencimientoFormatted`]="{ item }">
          <span style="font-size: 12px"
            >{{ item.fechaVencimientoFormatted }}
          </span>
        </template>
        <template v-slot:[`item.total`]="{ item }">
          <span style="font-size: 12px"
            >{{ helpers.floatToMoneyString(item.total, "", true) }}
          </span>
        </template>
        <template v-slot:[`item.cancelado`]="{ item }">
          <span style="font-size: 12px"
            >{{ helpers.floatToMoneyString(item.cancelado, "", true) }}
          </span>
        </template>
        <template v-slot:[`item.saldo`]="{ item }">
          <span style="font-size: 12px"
            >{{ helpers.floatToMoneyString(item.saldo, "", true) }}
          </span>
        </template>
        <template v-slot:[`item.saldoComp`]="{ item }">
          <span style="font-size: 12px"
            >{{ helpers.floatToMoneyString(item.saldoComp, "", true) }}
          </span>
        </template>
        <template v-slot:[`item.cotizacionComprobante`]="{ item }">
          <span style="font-size: 12px"
            >{{
              helpers.floatToMoneyString(item.cotizacionComprobante, "", true)
            }}
          </span>
        </template>
        <template v-slot:[`item.diferenciaCambio`]="{ item }">
          <span style="font-size: 12px"
            >{{ helpers.floatToMoneyString(item.diferenciaCambio, "", true) }}
          </span>
        </template>
        <template
          v-slot:[`item.cancela`]="{ item }"
          v-if="monedaProv != null && monedaProv.id == monedaPorDefecto"
        >
          <currency-input
            v-model="item.cancela"
            dense
            hide-details="auto"
            style="
              max-width: 140px;
              font-size: 12px;
              width: 140px;
              display: inline-block;
            "
            class="to-right right-input"
            outlined
            :options="currencyOptions"
            :rules="[rules.decimalEqualOrLess(item.cancela, item.saldo)]"
            @input="
              verificarYRemoverDeSeleccionados(item),
                (item.cancelaComp = item.cancela / cotizacionActual),
                calcularDiferenciaCambio(cotizacionActual)
            "
          ></currency-input>
        </template>
        <template
          v-slot:[`item.cancelaComp`]="{ item }"
          v-if="monedaProv != null && monedaProv.id != monedaPorDefecto"
        >
          <currency-input
            v-model="item.cancelaComp"
            dense
            hide-details="auto"
            style="
              max-width: 140px;
              font-size: 12px;
              width: 140px;
              display: inline-block;
            "
            class="to-right right-input"
            outlined
            @input="
              verificarYRemoverDeSeleccionados(item),
                (item.cancela = item.cancelaComp * cotizacionActual),
                calcularDiferenciaCambio(cotizacionActual)
            "
            :rules="[rules.decimalEqualOrLess(item.cancelaComp, item.saldo)]"
            :options="currencyOptions"
          ></currency-input>
        </template>
        <template v-slot:[`item.estadoComprobante.estado`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                :color="getEstadoColor(item.estadoComprobante.estado)"
                dark
                v-bind="attrs"
                v-on="on"
                small
              >
                {{ item.estadoComprobante.estado }}
              </v-chip>
            </template>
            <span>{{ item.estadoComprobante.data }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                small
                class="pl-2"
                v-on="on"
                @click="goToVerComprobante(item)"
              >
                {{ seeIcon }}
              </v-icon>
            </template>
            <span>Ver comprobante</span>
          </v-tooltip>
        </template>

        <template v-slot:[`header.data-table-select`]>
          <v-checkbox
            @click="
              {
                selectAllToggle();
              }
            "
            class="custom-checkbox-header"
            hide-details
            dense
            :disabled="comprobantes.length == disabledCount"
          />
        </template>

        <template
          v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
        >
          <v-simple-checkbox
            :value="isSelected"
            color="primary"
            class="custom-checkbox-table"
            @input="
              {
                select($event);
              }
            "
            :readonly="
              item.estadoComprobante.estado === 'Pendiente' && !esNotaCredito
            "
            :disabled="
              item.estadoComprobante.estado === 'Pendiente' && !esNotaCredito
            "
            :ripple="false"
            hide-details
          ></v-simple-checkbox>
        </template>
      </v-data-table>
    </v-card-text>
    <!-- MODAL BUSCAR PROVEEDOR -->
    <v-dialog
      v-model="buscarProveedorModal"
      v-if="buscarProveedorModal"
      @keydown.esc="toggleModalBusquedaProv"
      max-width="70%"
      persistent
    >
      <BuscarProveedor
        @toggleModalBusquedaProv="toggleModalBusquedaProv"
        @findProveedor="findProveedor"
      ></BuscarProveedor>
    </v-dialog>
    <v-dialog
      v-if="openSeeComp"
      v-model="openSeeComp"
      max-width="80%"
      @keydown.esc="openSeeComp = false"
      persistent
    >
      <DetalleComprobanteProvDialog
        @closeModalDelete="openSeeComp = false"
        :paramsToSeeCompDialog="paramsToSeeComp"
        :isDelete="false"
      ></DetalleComprobanteProvDialog>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import helpers from "@/utils/helpers.js";
import BuscarProveedor from "@/components/modules/proveedores/BuscarProveedor.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import FiltersSelected from "@/components/shared/FiltersSelected";
import DetalleComprobanteProvDialog from "@/components/modules/proveedores/cuentaCorriente/DetalleComprobanteProvDialog.vue";

export default {
  name: "BusquedaComprobantesCancelar",
  directives: { mask },
  components: {
    BuscarProveedor,
    CurrencyInput,
    FiltersSelected,
    DetalleComprobanteProvDialog
  },
  props: {
    empresa: {
      type: Object,
      required: true,
      default: () => ({})
    },
    proveedor: {
      type: Object,
      required: false,
      default: () => ({})
    },
    monedaProv: {
      type: Object,
      required: false,
      default: null
    },
    monedaOP: {
      type: Object,
      required: true,
      default: () => ({})
    },
    idsComprobantesExcluir: {
      type: Array,
      required: false,
      default: () => []
    },
    monedaPorDefecto: {
      type: Number,
      required: false,
      default: 1
    },
    cotizacionActual: {
      type: Number,
      required: false,
      default: 1
    },
    pagosMasivosFilter: {
      type: Boolean,
      required: false,
      default: false
    },
    esNotaCredito: {
      type: Boolean,
      required: false,
      default: false
    },
    tipoIvaProveedor: {
      type: Number,
      required: false,
      default: 0
    },
    showDiferenciaCambio: {
      type: Boolean,
      required: false,
      default: false
    },
    movProvId: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data: vm => ({
    seeIcon: enums.icons.SEE,
    calendarIcon: enums.icons.CALENDAR,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    closeIcon: enums.icons.CLOSE,
    searchIcon: enums.icons.SEARCH,
    openSeeComp: false,
    search: "",
    showFilters: true,
    buscarProveedorModal: false,
    provCod: null,
    provId: null,
    provNom: null,
    proveedorSelected: null,
    proveedoresLoading: null,
    rules: rules,
    tipoFecha: {
      id: 2,
      value: "Fecha comprobante"
    },
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: 0,
        max: 999999999999999
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: false,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true
    },
    helpers: helpers,
    filtersApplied: [],
    menuFechaDesde: null,
    fechaDesde: new Date(new Date().setMonth(new Date().getMonth() - 6))
      .toISOString()
      .substr(0, 10),
    fechaDesdeSelected: helpers.formatDate(
      new Date(new Date().setMonth(new Date().getMonth() - 6))
        .toISOString()
        .substr(0, 10)
    ),
    menuFechaHasta: null,
    fechaHastaSelected: helpers.formatDate(
      new Date(new Date()).toISOString().substr(0, 10)
    ),
    fechaHasta: new Date().toISOString().substr(0, 10),
    tiposFecha: [
      {
        id: 1,
        value: "Fecha contable"
      },
      {
        id: 2,
        value: "Fecha comprobante"
      },
      {
        id: 3,
        value: "Fecha vencimiento"
      }
    ],
    comprobantes: [],
    selected: [],
    total: null,
    isLoadingTable: false,
    tiposValoresProv: [],
    tipoValorProvSelected: null,
    modalidadPago: null,
    tiposProveedor: [],
    tipoProveedorSelected: null,
    tiposServicio: [
      {
        id: 1,
        value: "Todos"
      },
      {
        id: 2,
        value: "Servicios"
      },
      {
        id: 3,
        value: "Prestaciones"
      }
    ],
    tipoServicioSelected: 1,
    periodo: null,
    convenios: [],
    convenioSelected: [],
    agrupacionesGeografica: [],
    agrupGeograficaSelected: null,
    gruposLocalidades: [],
    grupoLocalidadesSelected: [],
    mSCloseBoxIcon: enums.icons.CLOSE_BOX,
    checkBoxBlankOutlineIcon: enums.icons.CHECKBOX_BLANK_OUTLINE,
    isFormValid: false,
    isApplyLoading: false,
    paramsToSeeComp: {}
  }),
  computed: {
    disabledCount() {
      let result = 0;

      if (this.comprobantes.length > 0) {
        this.comprobantes.map(item => {
          if (
            item.estadoComprobante.estado === "Pendiente" &&
            !this.esNotaCredito
          )
            result += 1;
        });
      }

      return result;
    },
    multiselectIconGrupoLocalidades() {
      if (this.selectAllGrupoLocalidades) return this.mSCloseBoxIcon;
      return this.checkBoxBlankOutlineIcon;
    },
    selectAllGrupoLocalidades() {
      return (
        this.grupoLocalidadesSelected &&
        this.grupoLocalidadesSelected.length === this.gruposLocalidades.length
      );
    },
    computedHeaders() {
      const headers = [
        { text: "Comprobante", value: "nroComp", sortable: false, order: 2 },
        {
          text: "Fecha comp.",
          value: "fechaComprobanteFormatted",
          sortable: false,
          width: "2px",
          order: 3
        },
        {
          text: "Vencimiento",
          value: "fechaVencimientoFormatted",
          sortable: false,
          order: 4
        },
        {
          text: "Total",
          value: "total",
          sortable: false,
          align: "end",
          order: 5
        },
        {
          text: "Cancelado",
          value: "cancelado",
          sortable: false,
          align: "end",
          order: 6
        },
        {
          text: "Saldo",
          value:
            this.monedaProv?.id != this.monedaPorDefecto
              ? "saldoComp"
              : "saldo",
          sortable: false,
          align: "end",
          order: 7
        },
        {
          text: "Cancela",
          value:
            this.monedaProv?.id != this.monedaPorDefecto
              ? "cancelaComp"
              : "cancela",
          sortable: false,
          align: "end",
          order: 8
        },
        {
          text: "Acc.",
          value: "actions",
          sortable: false,
          align: "end",
          order: 12
        }
      ];

      if (!this.esNotaCredito) {
        headers.splice(0, 0, {
          text: "Estado",
          value: "estadoComprobante.estado",
          sortable: false,
          align: "center",
          order: 11
        });
      }

      if (this.pagosMasivosFilter) {
        if (this.proveedorSelected == null) {
          headers.splice(0, 0, {
            text: "Proveedor",
            value: "provNom",
            width: "100px",
            sortable: false,
            order: 1
          });
        }
      }

      if (this.showDiferenciaCambio) {
        headers.splice(0, 0, {
          text: "Cotiz. comp.",
          value: "cotizacionComprobante",
          sortable: false,
          align: "end",
          order: 9
        });
        headers.splice(0, 0, {
          text: "Dif. de cambio",
          value: "diferenciaCambio",
          sortable: false,
          align: "end",
          order: 10
        });
      }
      return headers.sort(function(a, b) {
        return a.order - b.order;
      });
    }
  },
  watch: {
    selected: {
      deep: true,
      handler(val) {
        this.recalcularTotal();
        val.forEach(x => {
          if (
            this.monedaProv != null &&
            this.monedaProv.id != this.monedaPorDefecto
          ) {
            if (x.cancelaComp == 0) x.cancelaComp = x.saldoComp;
          } else {
            if (x.cancela == 0) x.cancela = x.saldo;
          }
        });
        this.comprobantes.forEach(c => {
          const match = val.find(s => s.mProvId == c.mProvId);
          if (
            this.monedaProv != null &&
            this.monedaProv.id != this.monedaPorDefecto
          ) {
            if (match != null) c.cancelaComp = match.cancelaComp;
            else {
              c.cancelaComp = 0;
              c.diferenciaCambio = 0;
            }
          } else {
            if (match != null) c.cancela = match.cancela;
            else {
              c.cancela = 0;
              c.diferenciaCambio = 0;
            }
          }
        });
        val.forEach(x => {
          if (
            this.monedaProv != null &&
            this.monedaProv.id != this.monedaPorDefecto
          ) {
            x.diferenciaCambio =
              x.cancelaComp * this.cotizacionActual -
              x.cancelaComp * x.cotizacionComprobante;
          } else {
            x.diferenciaCambio = 0
          }
        });
      }
    },
    comprobantes: {
      deep: true,
      handler() {
        this.recalcularTotal();
      }
    }
  },

  created() {
    if (this.pagosMasivosFilter) this.setSelects();
  },
  mounted() {
    this.applyFilters();
  },
  methods: {
    ...mapActions({
      searchComprobantesACancelar: "proveedores/searchComprobantesACancelar",
      searchComprobantesACancelarPagosMasivos:
        "proveedores/searchComprobantesACancelarPagosMasivos",
      getTiposValoresInProveedores: "proveedores/getTiposValoresInProveedores",
      getAgrupacionGeografica: "analytics/getAgrupacionGeografica",
      fetchTiposProveedores: "proveedores/fetchTiposProveedores",
      getEstructurasByAgruId: "proveedores/getEstructurasByAgruId",
      getProveedoresByCod: "afiliaciones/getProveedoresByCod",
      getMonedaDeProveedor: "proveedores/getMonedaDeProveedor",
      getConvenios: "afiliaciones/getConvenios",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const tiposValores = await this.getTiposValoresInProveedores();
      this.tiposValoresProv = tiposValores;
      const fetchTiposProveedores = await this.fetchTiposProveedores();
      this.tiposProveedor = fetchTiposProveedores;
      const agrupacionesGeograficas = await this.getAgrupacionGeografica();
      this.agrupacionesGeografica = agrupacionesGeograficas;
      const convenios = await this.getConvenios();
      this.convenios = convenios;
    },
    async setEstructuras() {
      if (this.agrupGeograficaSelected != null) {
        const estructuras = await this.getEstructurasByAgruId(
          this.agrupGeograficaSelected?.id
        );
        this.gruposLocalidades = estructuras;
      } else this.gruposLocalidades = [];
    },
    async applyFilters() {
      this.isLoadingTable = true;
      this.isApplyLoading = true;
      this.customizeFiltersApplied();
      this.showFilters = false;
      try {
        let response;
        let data;
        if (this.pagosMasivosFilter) {
          data = {
            empId: this.empresa.id,
            provId: this.provId ?? 0,
            tvId: this.tipoValorProvSelected?.id ?? 0,
            modalidadPago: this.modalidadPago ?? "",
            tipoProvId: this.tipoProveedorSelected?.id ?? 0,
            tipoFechaId: this.tipoFecha.id,
            monedaId: this.monedaProv?.id,
            fechaDesde: this.fechaDesde,
            fechaHasta: this.fechaHasta,
            cotizacionActual: parseFloat(this.cotizacionActual),
            periodo: this.periodo ?? "",
            estructuraId:
              this.grupoLocalidadesSelected.map(x => x.id).join(",") ?? ""
          };
          response = await this.searchComprobantesACancelarPagosMasivos(data);
        } else {
          data = {
            mProvId: this.movProvId ?? 0,
            empId: this.empresa.id,
            provId: this.proveedor.id,
            tipoFechaId: this.tipoFecha.id,
            monedaId:
              this.monedaProv != null
                ? this.monedaProv?.id
                : this.monedaPorDefecto,
            fechaDesde: this.fechaDesde,
            fechaHasta: this.fechaHasta,
            cotizacionActual: parseFloat(this.cotizacionActual)
          };
          response = await this.searchComprobantesACancelar(data);
        }

        this.comprobantes = response;
        this.comprobantes = this.comprobantes.filter(
          item => !this.idsComprobantesExcluir.includes(item.mProvId)
        );
      } catch (error) {
        console.error(error);
      }
      this.isLoadingTable = false;
      this.isApplyLoading = false;
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.empresa) {
        this.filtersApplied.splice(0, 1, {
          key: "empresa",
          label: "Empresa",
          model: this.empresa.value
        });
      }
      if (this.monedaProv != null) {
        this.filtersApplied.splice(1, 1, {
          key: "monedaProv",
          label: "Moneda",
          model: this.monedaProv.value
        });
      }
      if (this.pagosMasivosFilter) {
        if (this.proveedorSelected) {
          this.filtersApplied.splice(2, 1, {
            key: "proveedorSelected",
            label: "Proveedor",
            model: this.provNom
          });
        }
      } else {
        if (this.proveedor) {
          this.filtersApplied.splice(2, 1, {
            key: "proveedor",
            label: "Proveedor",
            model: this.proveedor.value1
          });
        }
      }
      if (this.tipoFecha) {
        this.filtersApplied.splice(3, 1, {
          key: "tipoFecha",
          label: "Tipo fecha",
          model: this.tipoFecha.value
        });
      }
      if (this.fechaDesde) {
        this.filtersApplied.splice(4, 1, {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.fechaDesdeSelected
        });
      }
      if (this.fechaHasta) {
        this.filtersApplied.splice(5, 1, {
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.fechaHastaSelected
        });
      }
      if (this.tipoValorProvSelected) {
        this.filtersApplied.splice(6, 1, {
          key: "tipoValorProvSelected",
          label: "Tipo valor",
          model: this.tipoValorProvSelected.value
        });
      }
      if (this.modalidadPago) {
        this.filtersApplied.splice(7, 1, {
          key: "modalidadPago",
          label: "Modalidad de pago",
          model: this.modalidadPago
        });
      }
      if (this.tipoProveedorSelected) {
        this.filtersApplied.splice(8, 1, {
          key: "tipoProveedorSelected",
          label: "Tipo proveedor",
          model: this.tipoProveedorSelected.value
        });
      }
      if (this.periodo) {
        this.filtersApplied.splice(9, 1, {
          key: "periodo",
          label: "Período",
          model: this.periodo
        });
      }
      if (this.agrupGeograficaSelected) {
        this.filtersApplied.splice(10, 1, {
          key: "agrupacionGeografica",
          label: "Agrupación geográfica",
          model: this.agrupGeograficaSelected?.value
        });
      }
      if (this.grupoLocalidadesSelected.length > 0) {
        this.filtersApplied.splice(11, 1, {
          key: "grupoLocalidades",
          label: "Grupo de localidades",
          model: this.grupoLocalidadesSelected
        });
      }
    },
    recalcularTotal() {
      let total = 0;

      this.selected.forEach(selectedComprobante => {
        const comprobante = this.comprobantes.find(
          c => c.mProvId === selectedComprobante.mProvId
        );
        if (comprobante) {
          if (this.monedaPorDefecto !== this.monedaProv?.id) {
            total += comprobante.cancelaComp || 0;
            selectedComprobante.cancelaComp = comprobante.cancelaComp;
          } else {
            total += comprobante.cancela || 0;
            selectedComprobante.cancela = comprobante.cancela;
          }
        }
      });

      this.total = helpers.floatToMoneyString(total, "", true);
    },
    findProveedor(proveedor) {
      this.proveedorSelected = proveedor;
      this.provId = this.proveedorSelected.provId;
      this.provNom = proveedor.provNom;
      this.provCod = proveedor.provCod;
      this.proveedorSelected.value1 = this.provNom.toUpperCase();
      this.proveedorSelected.value2 = this.provCod;
      this.proveedorSelected.id = this.provId;
      this.comprobantes = [];
      this.getMonedaProveedor();
      this.resetProvFields();
    },
    async getMonedaProveedor() {
      if (this.provId != null) {
        const response = await this.getMonedaDeProveedor(this.provId);
        if (response.id != this.monedaProv?.id) {
          this.proveedorSelected = this.provId = this.provNom = this.provCod = null;
          this.setAlert({
            type: "warning",
            message:
              "No es posible seleccionar el proveedor ya que la moneda de proveedor seleccionada no coincide con la moneda configurada del proveedor."
          });
          this.resetProvFields();
        }
      }
    },
    goToVerComprobante(item) {
      this.openSeeComp = true;
      this.paramsToSeeComp = {
        movProvId: item.mProvId,
        monedaToDetalle: this.monedaProv,
        fechaCotizaToDetalle: this.parseDateToIso(
          item.fechaComprobanteFormatted
        )
      };
    },
    calcularDiferenciaCambio(cotizacionParam) {
      if (this.monedaProv?.id == this.monedaPorDefecto) {
        this.comprobantes = this.comprobantes.map(comprobante => {
          // Calcula la diferencia de cambio
          const diferenciaCambio =
            comprobante.cancela * cotizacionParam -
            comprobante.cancela * comprobante.cotizacionComprobante;

          // Retorna un nuevo objeto con el valor actualizado
          return {
            ...comprobante,
            diferenciaCambio: diferenciaCambio
          };
        });
      } else if (this.monedaProv?.id != this.monedaPorDefecto) {
        this.comprobantes = this.comprobantes.map(comprobante => {
          // Calcula la diferencia de cambio pero en base al cancelaComp
          const diferenciaCambio =
            comprobante.cancelaComp * cotizacionParam -
            comprobante.cancelaComp * comprobante.cotizacionComprobante;

          return {
            ...comprobante,
            diferenciaCambio: diferenciaCambio
          };
        });
      }
      // Actualiza 'diferenciaCambio' de 'selected' con los comprobantes correspondientes
      this.selected = this.selected.map(selectedItem => {
        const comprobanteCorrespondiente = this.comprobantes.find(
          comprobante => comprobante.mProvId === selectedItem.mProvId
        );

        if (comprobanteCorrespondiente) {
          return {
            ...selectedItem,
            diferenciaCambio: comprobanteCorrespondiente.diferenciaCambio
          };
        }
        return selectedItem;
      });
    },
    onClear() {
      this.$refs.proveedorTextField.blur();
    },
    resetProvFields() {
      this.modalidadPago = this.tipoProveedorSelected = this.tipoValorProvSelected = null;
      this.comprobantes = [];
      if (this.provNom == null) {
        this.proveedorSelected = null;
      }
    },
    agregarSeleccionados() {
      this.$emit("setComprobantesCancelar", this.selected);
      this.closeModal();
    },
    getEstadoColor(estado) {
      switch (estado) {
        case "Autorizado":
          return "green";
        case "Pendiente":
          return "orange";
        case "No requiere":
          return "blue";
        default:
          return "grey";
      }
    },
    selectAllToggle() {
      if (
        this.selected.length !=
        this.comprobantes.length - this.disabledCount
      ) {
        this.selected = [];
        this.selected = !this.esNotaCredito
          ? this.comprobantes.filter(
              x =>
                x.estadoComprobante.estado === "Autorizado" ||
                x.estadoComprobante.estado === "No requiere"
            )
          : this.comprobantes;

        this.setAlert({
          type: "info",
          message: `Todos los comprobantes ${
            this.esNotaCredito
              ? ""
              : "autorizados o que no requieren autorización "
          }han sido seleccionados.`
        });
      } else if (
        this.comprobantes.length === this.disabledCount &&
        !this.esNotaCredito
      ) {
        this.selected = [];
        this.setAlert({
          type: "warning",
          message: "No se puede seleccionar ningún comprobante."
        });
      } else {
        this.selected = [];
        this.setAlert({
          type: "info",
          message: "Todos los comprobantes han sido deseleccionados."
        });
      }
      setTimeout(() => {
        this.calcularDiferenciaCambio(this.cotizacionActual);
      }, 100);
    },
    verificarYRemoverDeSeleccionados(item) {
      const verificarItem =
        this.monedaProv?.id == this.monedaPorDefecto
          ? item.cancela == null ||
            item.cancela === "" ||
            item.cancela == 0 ||
            item.cancela > item.saldo
          : item.cancelaComp == null ||
            item.cancelaComp === "" ||
            item.cancelaComp == 0 ||
            item.cancelaComp > item.saldoComp;

      if (verificarItem) {
        const index = this.selected.findIndex(x => x.mProvId == item.mProvId);
        if (index !== -1) {
          this.selected.splice(index, 1);
        }
      }
    },
    grupoLocalidadesToggle() {
      this.$nextTick(() => {
        if (this.selectAllGrupoLocalidades) this.grupoLocalidadesSelected = [];
        else this.grupoLocalidadesSelected = this.gruposLocalidades;
      });
    },
    closeModal() {
      this.$emit("toggleModalComprobantesCancelar");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    toggleModalBusquedaProv() {
      this.buscarProveedorModal = !this.buscarProveedorModal;
    }
  }
};
</script>

<style scoped>
.text-right {
  text-align: right;
}
.right-input >>> input {
  text-align: right;
}
.custom-checkbox-table {
  transform: scale(0.7);
  margin: 0;
}
.custom-checkbox-header {
  transform: scale(0.8);
  margin: 0;
  padding: 0;
}
.custom-data-table >>> .v-data-table-header th,
.custom-data-table >>> .v-data-table__divider,
.custom-data-table >>> .v-data-table__wrapper td,
.custom-data-table >>> .v-data-table__mobile-table-row {
  padding: 4px 8px;
  margin: 0;
}
</style>
